import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Lightbox from "../components/lightBox";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import {
  FaClock,
  FaList,
  FaUsers,
  FaPuzzlePiece,
  FaSchool,
  FaPlus,
  FaSlidersH,
  FaLaptop,
  FaCreditCard,
  FaSmileBeam,
  FaPeopleCarry,
  FaGraduationCap,
  FaGift,
  FaTh,
} from "react-icons/fa";
import Container from "../components/container";
import { BsFillAlarmFill,
BsFillBriefcaseFill,
BsFillGiftFill,
BsEmojiLaughingFill,
BsToggles,
BsGridFill,
 } from "react-icons/bs";
import * as styles from "./index.module.css";
import Tile from "../components/Tile";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import heroImage2 from "../images/system-crm-dla-firmy-handlowej-i-uslugowej.jpg";
import Columns from "../components/columns";
import ResponsiveEmbed from "react-responsive-embed";

export const query = graphql`
  {
    rekrutacja: file(relativePath: { eq: "dolaczdonas.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const FrontendDeveloper = ({ data }) => {
  return (
    <FunctionPageLayout title="Frontend Developer" headline="Dołącz do zespołu">
      <SEO
        title="Frontend Developer- oferta pracy"
        description="Producent oprogramowania dla firm ✅ Oferta pracy • Frontend Developer • Skontaktuj się z nami"
      />
      <Container>
        <h2
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      > Kim jesteśmy? </h2>
      <p>
       Jesteśmy producentem i dostawcą oprogramowania działającym na polskim rynku już
        od 2002 roku. Pracujemy zarówno dla dużych, rozpoznawalnych firm, jak&nbsp;i&nbsp;dla 
        mniejszych, dynamicznie rozwijających się przedsiębiorstw. Nasz zespół składa się z&nbsp;ponad 50
        specjalistów, których łączy wspólna pasja – technologie informacyjne. </p>
        <p>Fundamentem naszej codziennej działalności jest<strong> dostarczanie rozwiązań informatycznych, które
        efektywnie wspierają rozwój biznesu</strong>. Naszą ambicją i&nbsp;długofalową wizją – zostanie najlepszym 
        dostawcą systemów IT wykorzystywanych w&nbsp;organizacjach na całym świecie.
        </p>
        <h2  style={{
          paddingTop: 20,
          paddingBottom: 15,
        }}>Wierzymy, że:</h2>
        <p><ul>
        <li>tworząc rozwiązania wysokiej jakości, przyczyniamy się do rozwoju naszych klientów,</li>
        <li>miarą naszego sukcesu jest sukces klienta i&nbsp;z&nbsp;zaangażowaniem walczymy o jego osiągnięcie,</li>
        <li>nasi pracownicy i klienci są partnerami, którzy wspólnie budują długoterminową przyszłość,</li>
        <li>dzięki współpracy osiągniemy więcej niż każdy z&nbsp;nas działając osobno,</li>
        <li>inicjatywa i zaangażowanie są podstawą rozwoju i&nbsp;zasługują na uznanie,</li>
        <li>ciągłe doskonalenie metod działania oraz&nbsp;produktu jest dla nas czymś oczywistym.</li></ul></p>
      </Container>
      <h2
        style={{
          paddingBottom: 30,
          paddingTop: 20,
        }}
      >
        Tworzymy zgrany zespół
      </h2>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p>
              To, co motywuje nas do codziennego działania to współpraca. <br />
              Każdego dnia wspólnie tworzymy projekty, rozwiązujemy problemy
              naszych klientów, świętujemy nasze sukcesy, świetnie się przy tym
              wszystkim bawiąc.
              <br />
              <strong>
                {" "}
                Przecież w dobrej atmosferze obowiązki stają się przyjemnością,
                prawda?
              </strong>
            </p>
          </div>
          <div className="text-block">
            <ResponsiveEmbed
              src="https://www.youtube.com/embed/lLKbdB1I0xo"
              allowFullScreen
              ratio="16:9"
            />
          </div>
        </div>
      </Container>
      <h2
        style={{
          paddingTop: 30,
          paddingBottom: 30,
          textAlign: "center",
        }}
      >
        {" "}
        Dlaczego warto z nami pracować?{" "}
      </h2>

      <div>
        <BenefitBlockWrapper>
          <BenefitBlock
            icon={<BsFillBriefcaseFill size="60px" />}
            title="Już od 2002 roku budujemy doświadczenie w&nbsp;biznesie"
          />
          <BenefitBlock
            icon={<BsEmojiLaughingFill size="60px" />}
            title="Ponad 50 zapalonych fanów technologii na&nbsp;pokładzie"
          />
          <BenefitBlock
            icon={<BsGridFill size="60px" />}
            title="Realizujemy projekty z&nbsp;różnych dziedzin"
          />
        </BenefitBlockWrapper>
        <BenefitBlockWrapper>
          <BenefitBlock
            icon={<FaGraduationCap size="60px" />}
            title="Dzielimy się wiedzą i&nbsp;doświadczeniem"
          />
          <BenefitBlock
            icon={<BsToggles size="60px" />}
            title="Jesteśmy elastyczni i&nbsp;do każdego podchodzimy indywidualnie"
          />
          <BenefitBlock
            icon={<BsFillGiftFill size="60px" />}
            title="Oferujemy dodatkowe benefity"
          />
        </BenefitBlockWrapper>
      </div>

      <br />
      <h2> Oferta pracy </h2>
      <center>
        <h3>Frontend Developer</h3>
      </center>
      <br />
      <p>
      <center>
        <strong>
          Twoim celem będzie tworzenie wartościowych rozwiązań dla naszych klientów.
        </strong>
        </center>
      </p>
      <br />
      <p>
        <strong>Czym będziesz się zajmować?</strong>
      </p>
      <p>
        <ul>
          <li>
            projektowaniem oraz realizacją zmian programistycznych,
          </li>
          <li>
            weryfikacją funkcjonalności, ergonomii, wydajności oraz dokumentacji systemu,
          </li>
          <li>tworzeniem testów jednostkowych oraz integracyjnych.</li>
        </ul>
      </p>
      <br />
      <p>
        <strong>Jakiej osoby szukamy?</strong>
      </p>
      <p>
        <ul>
          <li>inteligentnej z&nbsp;analitycznym umysłem,</li>
          <li>systematycznej i&nbsp;dokładnej,</li>
          <li>potrafiącej ustalać priorytety oraz organizować pracę,</li>
          <li>posiadającej pozytywną energię i&nbsp;pozytywne nastawienie do wyzwań,</li>
          <li>postrzegającej problem jako okazję do doskonalenia,</li>
          <li>
            otwartej na opinie innych w&nbsp;celu poszerzania własnych horyzontów.
          </li>
                 </ul>
      </p>
      <br />
      <p>
        <strong>Czego wymagamy?</strong>
      </p>
      <p>
      <ul>
      <li>Znajomości: HTML, CSS3, JavaScript ES6, React, REST, Git.
      </li>
      </ul>
      </p>

      <br />
      <p>
        <strong>Dobrze, jeśli:</strong>
      </p>
      <p>
      <ul>
      <li>posiadasz wiedzę z&nbsp;zakresu: Typescript, Rush.js, Next.js, Jest, Cypress, Docker,
      Figma, React Native,
      </li>
      <li>pracowałeś wcześniej w&nbsp;SCRUMie.
      </li>
      </ul>
      </p>
      <br />
      <p><strong>Co zyskujesz dołączając do naszego zespołu?</strong></p>
      <p>
      <ul>
      <li>Szansę na poznanie świata IT od kuchni.</li>
      <li>Możliwość zdobycia wiedzy oraz praktycznego doświadczenia.</li>
      <li>Pracę w&nbsp;systemie jednozmianowym.</li>
      <li>Szansę na długoterminową współpracę po 3-miesięcznym okresie próbnym.</li>
      <li>Elastyczny grafik, jeśli jesteś studentką/studentem.</li>
      <li>Ciekawą pracę w dynamicznej firmie nastawionej na przyszłość i&nbsp;rozwój.</li>
      <li><strong>Szansę na realizowanie własnych pomysłów,</strong> bo w&nbsp;naszej firmie stawiamy na innowacyjność.</li>
      <li>Przyjazną atmosferę i&nbsp;spotkania integracyjne.</li>
      <li>Pracę w biurze w centrum Poznania (3 min pieszo od Dworca Zachodniego).</li>
      <li>Dofinansowanie do karty Multisport.</li>
      <li>Możliwość pracy w trybie hybrydowym.</li>
      <li>Zatrudnienie w formie zlecenia lub umowy o&nbsp;pracę.</li>
      </ul>
      </p>
      <br />
      <p><strong>Jeśli spełniasz większość z&nbsp;podanych przez nas wymagań, śmiało APLIKUJ!</strong></p>
      <br />
      

      <Container>
        <h2
          style={{
            textAlign: "center",
            paddingTop: 20,
          }}
        >
          Przekonaliśmy Cię?
          </h2>
          
          <p
        style={{
          paddingBottom: 20,
          textAlign: "center",
        }}
      >
        Zobacz, jak do nas dołączyć.{" "}
      </p>
        <GatsbyImage
          image={data.rekrutacja.childImageSharp.gatsbyImageData}
          style={{
            maxWidth: 1200,
            margin: "0 auto",
            marginTop: 30,
          }}
          alt="rekrutacja"
        />

        <br />
        <br />
      </Container>
      <h2> Czekamy na Ciebie! </h2>
      <br />
      <br />
      <center>
        <p>
          Wyślij CV z dopiskiem <strong>„Frontend Developer”</strong> oraz zgodą
          na przetwarzanie danych osobowych w procesie rekrutacji na adres
          <strong> kariera@questy.pl </strong>{" "}
        </p>

        <p>
          {" "}
          Informujemy, że skontaktujemy się osobiście tylko z wybranymi
          kandydatami.{" "}
        </p>
      </center>
      <br />
      <br />
      <br />

      <p>Prosimy o zawarcie w CV klauzuli: </p>

      <p>
        „Wyrażam zgodę na przetwarzanie danych osobowych zawartych w mojej
        ofercie pracy dla potrzeb niezbędnych do realizacji procesu rekrutacji
        prowadzonego przez QUESTY z&nbsp;siedzibą w Poznaniu zgodnie z&nbsp;ustawą z&nbsp;dnia
        29 sierpnia 1997 r. o ochronie danych osobowych (t.j. Dz. U. z 2016 r.,
        poz. 922)”. Jednocześnie wyrażam zgodę na przetwarzanie przez
        ogłoszeniodawcę moich danych osobowych na potrzeby przyszłych
        rekrutacji.”
      </p>
      <p>
        Informujemy, że Administratorem danych jest QUESTY z siedzibą w Poznaniu
        przy ul. Głogowskiej 31/33. Dane zbierane są dla potrzeb rekrutacji. Ma
        Pani/Pan prawo dostępu do treści swoich danych oraz ich poprawiania.
        Podanie danych w zakresie określonym przepisami ustawy z dnia 26 czerwca
        1974 r. Kodeks pracy oraz aktów wykonawczych jest obowiązkowe. Podanie
        dodatkowych danych osobowych jest dobrowolne.
      </p>
    </FunctionPageLayout>
  );
};

export default FrontendDeveloper;
